.customScroll::-webkit-scrollbar {
	width: 7px;
}
.customScroll::-webkit-scrollbar-track {
	background: #f1f1f1;
}
.customScroll::-webkit-scrollbar-thumb {
	background: #888;
}
.customScroll::-webkit-scrollbar-thumb:hover {
	background: #555;
}

iframe#webpack-dev-server-client-overlay {
	display: none !important;
}

html,
body {
	height: 100%;
}

body {
	background-color: var(--background-color);
	overflow-x: hidden;
	margin: 0;
	color: var(--font-color);
}

.roundBtn {
	display: inline-block;
	border-radius: 50%;
	background-color: transparent;
	background: url('../img/roundBtn.png') no-repeat;
	background-size: 37px 37px;
	border: none;
	color: var(--btn-font-color);
	text-align: center;
	font-size: 1rem;
	font-weight: bold;
	padding-left: 1.75rem;
	padding-right: 1.75rem;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	height: 37px;
	width: 37px;
	transition: all 0.5s;
	cursor: pointer;
	vertical-align: middle;
}

.roundBtn:hover {
	filter: brightness(1.2);
}

.rectBtn {
	display: inline-block;
	border-radius: 4px;
	background-color: transparent;
	background: url('../img/rectBtn.png') no-repeat;
	background-size: 100% 37px;
	border: none;
	color: var(--btn-font-color);
	text-align: center;
	font-size: 1rem;
	font-weight: bold;
	padding-left: 1.75rem;
	padding-right: 1.75rem;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	height: 37px;
	transition: all 0.5s;
	cursor: pointer;
	vertical-align: middle;
}

.rectBtn span {
	cursor: pointer;
	display: inline-block;
	position: relative;
	transition: 0.5s;
}

.rectBtn span:after {
	content: '\00bb';
	position: absolute;
	opacity: 0;
	top: 0;
	right: -20px;
	transition: 0.5s;
}

.rectBtn:hover {
	filter: brightness(1.2);
}

.rectBtn:hover span {
	padding-right: 15px;
}

.rectBtn:hover span:after {
	opacity: 1;
	right: 0;
}

a.roundBtn:hover,
a.rectBtn:hover {
	color: var(--btn-font-color);
}

.rectBtn:disabled,
.rectBtn[disabled],
.roundBtn:disabled,
.roundBtn[disabled] {
	opacity: 0.6;
	pointer-events: none;
}

a,
a:hover {
	text-decoration: none;
}

.defaultLink {
	text-decoration: none;
}

.myTable {
	color: var(--font-color);
}

.text-main {
	color: var(--font-color) !important;
}
.text-navy {
	color: var(--my-navy) !important;
}
.text-purple {
	color: var(--my-purple) !important;
}
.text-green {
	color: var(--my-green) !important;
}
.text-blue {
	color: var(--my-blue) !important;
}
.text-link-color {
	color: var(--my-link-color) !important;
}
a.text-link-color:hover {
	color: var(--my-link-color-hover) !important;
}
.text-brown {
	color: var(--my-brown) !important;
}
.text-dark-brown {
	color: var(--my-dark-brown) !important;
}
.text-my-primary {
	color: var(--my-primary) !important;
}
.text-my-success {
	color: var(--my-success) !important;
}
.text-my-danger {
	color: var(--my-danger) !important;
}

.borderRight {
	border-right: #6565654b solid 1px;
}

@media (max-width: 767.98px) {
	.borderRight {
		border-right: none;
	}
	.myTable {
		font-size: 12px !important;
	}
}

.myImages {
	border-radius: 15px;
}

.myCards {
	box-shadow: 0 30px 90px -20px rgb(0 0 0 / 30%),
		0 0 1px 1px rgba(255, 255, 255, 0.05);
	border-color: transparent;
	border-radius: 15px;
}

.myActiveNavLink {
	background-color: #ffffff6c;
	border-radius: 0 2rem 2rem 0;
}

.myPagination {
	color: white;
}

.myPagination li span {
	color: var(--my-link-color) !important;
	padding: 0.375rem 0.6rem !important;
	background-color: unset;
	border: 1px solid var(--my-link-color) !important;
}

.myPagination li span:hover {
	cursor: pointer;
	color: var(--my-dark-brown) !important;
	background-color: var(--my-link-color);
	border-color: var(--my-link-color);
}

.myActive {
	background-color: var(--my-brown);
	border-color: var(--my-brown) !important;
}

.myActive span:hover {
	background-color: var(--my-brown) !important;
	border-color: var(--my-brown);
}

.myListGroupItem {
	background-color: transparent;
	color: var(--my-link-color) !important;
	border-color: var(--my-link-color) !important;
}

.myListGroupItemActive {
	background-color: var(--my-brown) !important;
}

.myListGroupItem:hover {
	cursor: pointer;
	color: var(--my-dark-brown) !important;
	background-color: var(--my-link-color);
	border-color: var(--my-link-color);
}

.myListGroupItem:hover img,
.myListGroupItemActive img {
	background-color: white;
	border-radius: 50%;
	padding: 2px;
}

.myModal .modal-content {
	background-color: var(--my-brown);
}

.accordion-button:hover {
	background: rgba(255, 255, 255, 0.2) !important;
	border-radius: 2rem !important;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}

.loginPage {
	background-image: url('../img/loginBack.jpg');
	background-color: #010b17;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
}

.coin-logo {
	height: 24px;
	width: 24px;
	border-radius: 12px;
}

@keyframes shine {
	10% {
		opacity: 1;
		top: -30%;
		left: -30%;
		transition-property: left, top, opacity;
		transition-duration: 0.7s, 3s, 0.15s;
		transition-timing-function: ease;
	}
	100% {
		opacity: 0;
		top: -30%;
		left: -30%;
		transition-property: left, top, opacity;
	}
}

/* animation */

@keyframes slide {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.loginCard {
	background: var(--my-brown);
	box-shadow: -12px 11px 31px #5f280070, 13px -11px 31px #5f280070;
	border-radius: 30px;
	border-color: transparent;
	color: white;
}

.loginCard a {
	color: var(--my-link-color);
}

.seedsCard {
	background: var(--my-brown);
	box-shadow: -12px 11px 31px #5f280070, 13px -11px 31px #5f280070;
	border-radius: 30px;
	border-color: transparent;
	color: white;
}

.shadowfilter {
	-webkit-filter: drop-shadow(1px 1px 6px rgba(238, 253, 165, 0.881));
	filter: drop-shadow(1px 1px 6px rgba(238, 253, 165, 0.881));
}

.graySeed {
	filter: gray; /* IE6-9 */
	-webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
	filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

/* Disable grayscale on hover */
.graySeed:hover {
	-webkit-filter: grayscale(0);
	filter: none;
}

.innerTabPanels {
	border: 1px solid #dee2e6;
	border-top-color: transparent;
	border-radius: 0 0 5px 5px;
}

.my-footer {
	background: linear-gradient(
		to bottom,
		rgba(0, 129, 5, 1) 0%,
		rgba(0, 86, 3, 1) 100%
	) !important;
	margin-top: auto;
	color: white;
}

.my-footer-bottom {
	background-color: var(--background-color) !important;
	color: var(--my-green);
}

.socialSection a {
	color: white !important;
}

.socialIcons {
	width: 35px;
	height: 35px;
	border-radius: 25px;
	font-size: 20px;
	border: 1px white solid;
	text-decoration: none !important;
	color: white !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.socialIcons:hover {
	color: var(--my-green) !important;
	border-color: white;
	background: white;
}
