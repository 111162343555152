.bg {
	background: linear-gradient(
		to bottom,
		rgba(0, 129, 5, 1) 0%,
		rgba(0, 86, 3, 1) 100%
	) !important;
}

#myNav li a {
	color: white !important;
}

#myNav {
	margin-right: 1.5rem;
	margin-left: 10rem;
	padding-left: 5rem;
}

@media (max-width: 991.98px) {
	#myNav {
		border-radius: 0.5rem !important;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		padding-left: unset;
	}
}
