.contentsBody::-webkit-scrollbar {
	width: 3px;
}

.contentsBody::-webkit-scrollbar-track {
	background: var(--menu-color);
	width: 3px;
}

.contentsBody::-webkit-scrollbar-thumb {
	background: #888;
}

.contentsBody::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.dashboardPage {
	min-height: 100vh;
}

.summaryCard,
.crumbsComp {
	background: transparent !important;
	border-radius: 15px 15px 0 0 !important;
	border-color: transparent !important;
	box-shadow: 0 30px 90px -20px rgba(0, 0, 0, 0.4) !important;
	color: var(--menu-color);
}

.contentsCard {
	background-color: var(--my-dark-brown);
	border-radius: 15px;
	border-color: transparent;
	color: var(--menu-color);
}

.fancy {
	position: relative;
}

.fancy-numbers {
	z-index: 1;
	width: 100px;
	font-family: 'Playfair Display', serif;
	font-size: 4em;
	letter-spacing: -0.05em;
	font-style: italic;
	color: #e0f8822e;
}

.fancy-numbers::after {
	content: '';
	position: absolute;
	z-index: 3;
	top: 0px;
	left: 15px;
	width: 100px;
	height: 100px;
	border: solid 2px var(--my-link-color);
	-webkit-clip-path: polygon(
		0 0,
		0 110px,
		40px 110px,
		40px 10px,
		110px 10px,
		110px 0
	);
	clip-path: polygon(
		0 0,
		0 110px,
		40px 110px,
		40px 10px,
		110px 10px,
		110px 0
	);
}

.fancy-content {
	position: absolute;
	z-index: 2;
	top: 10px;
	left: 60px;
}

@media (max-width: 767px) {
	.summaryCard,
	.crumbsComp {
		background: var(--my-brown) !important;
		border-radius: 15px !important;
		box-shadow: none;
	}
}

.bounce-2 {
	animation-name: bounce-2;
	animation-timing-function: ease;
	animation-duration: 15s;
	animation-iteration-count: infinite;
}
@keyframes bounce-2 {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-100px);
	}
	100% {
		transform: translateY(0);
	}
}
