.sidebarBody::-webkit-scrollbar {
	width: 3px;
}

.sidebarBody::-webkit-scrollbar-track {
	background: var(--menu-color);
	width: 3px;
}

.sidebarBody::-webkit-scrollbar-thumb {
	background: #888;
}

.sidebarBody::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.sidebar {
	background: var(--my-brown) !important;
	border-radius: 15px !important;
	border-color: transparent !important;
	/* box-shadow: -12px 11px 31px #ffffff52, 13px -11px 31px #f4f4f461; */
}

.myNavLink {
	display: block;
	padding: 0.75rem 1rem;
	text-decoration: none;
	color: var(--menu-color);
	font-weight: 500;
}

.myNavLink:hover {
	cursor: pointer;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 2rem;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.3);
	color: var(--menu-color);
}

.myNavLink:not(:hover) {
	border-radius: 2rem;
	/* animation: fadeOut 0.65s; */
}

@keyframes fadeIn {
	0% {
		background-color: #ffffff00;
	}
	100% {
		background-color: #ffffff;
	}
}

@keyframes fadeOut {
	0% {
		background-color: #ffffff;
	}
	100% {
		background-color: #ffffff00;
	}
}

.filterButtons {
	color: var(--my-primary);
	border-top: rgb(91, 91, 91) solid 1px;
	font-weight: 500;
}

.myAccordion div:last-child > .filterButtons {
	border-bottom: rgb(91, 91, 91) solid 1px;
}

.myAccordion div:first-child > .filterButtons {
	border-top: none;
}

.filterButtons:hover {
	box-shadow: 0px 0px 20px 1px #3e3e3e94;
	cursor: pointer;
}

.buttonActiveClass {
	border-bottom: rgb(91, 91, 91) solid 1px;
	box-shadow: 0px 0px 20px 1px #3e3e3e94;
}

.contents {
	background-color: #2e2e2e;
	max-height: 280px;
	overflow-y: auto;
}
