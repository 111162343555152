@font-face {
	font-family: 'Segoe UI Regular';
	font-style: normal;
	font-weight: normal;
	src: local('Segoe UI Regular'), url('../fonts/Segoe UI.woff') format('woff');
}
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	src: local('Roboto'), url('../fonts/Roboto-Regular.ttf') format('ttf');
}

@import 'https://fonts.googleapis.com/css?family=Playfair+Display:700,700i';

:root {
	--my-primary: #7bbbff;
	--my-success: #23c57a;
	--my-danger: #ff5d5d;
	--background-color: #eff1d4;
	--font-color: #eff1d4;
	--btn-font-color: #5f2800;
	--menu-color: #eff1d4;
	--my-purple: #a441ff;
	--my-blue-light: #b8ffff;
	--my-blue: #30bcbc;
	--my-navy: #000460;
	--my-green-light: #9ead1c;
	--my-green: #008105;
	--my-link-color: #e1f882;
	--my-link-color-hover: #bac491;
	--my-brown: #734713;
	--my-dark-brown: #30180c;
	--background: url(../img/back.jpg);
}
