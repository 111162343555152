.navbar {
	background-color: var(--my-brown);
	color: var(--menu-color);
	border-bottom: 1px solid var(--my-dark-brown);
}

.navbar .navbar-toggler {
	top: 0.3rem !important;
	right: 0.5rem !important;
	scale: 0.8;
}
