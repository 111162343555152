.homeSection {
	border-radius: 2rem;
	margin: 1rem 1.5rem 1rem 1.5rem;
}

.hero img {
	border-radius: 2rem;
}

.about {
	background: linear-gradient(
		to right,
		var(--my-green-light) 0%,
		var(--my-green) 100%
	) !important;
	color: var(--background-color);
	margin-left: 15rem;
	padding-left: 5rem;
}

.tim {
	background: linear-gradient(
		to left,
		var(--my-blue-light) 0%,
		var(--my-blue) 100%
	) !important;
	color: #005f88;
	border-radius: 2rem 0 0 2rem;
	margin-left: 15rem;
	margin-right: 0;
	padding-left: 5rem;
}

.bcn {
	background-color: var(--my-navy);
	color: white;
}

@media (max-width: 991.98px) {
	.hero img {
		border-radius: 0.5rem;
	}
	.about,
	.tim,
	.bcn {
		margin-right: unset;
		margin-left: unset;
		padding-left: unset;
	}
	.homeSection {
		border-radius: 0.5rem;
		margin: 1rem 0.5rem 1rem 0.5rem;
	}
}

.rotated {
	transform: rotateZ(-15deg);
}
